import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender } from '../utils';
import ClientRender from './ClientRender'
import { EdgeImg } from './EdgeImg'

class Content400 extends React.PureComponent {
  getDelay = (e, b) => (e % b) * 100 + Math.floor(e / b) * 100 + b * 100;

  render() {
    const { ...props } = this.props;
    const { dataSource, isMobile } = props;
    delete props.dataSource;
    delete props.isMobile;
    let clearFloatNum = 0;
    const children = dataSource.block.children.map((item, i) => {
      const childObj = item.children;
      const delay = isMobile ? i * 50 : this.getDelay(i, 24 / item.md);
      const liAnim = {
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad',
        delay,
      };
      const childrenAnim = { ...liAnim, x: '+=10', delay: delay + 100 };
      clearFloatNum += item.md;
      clearFloatNum = clearFloatNum > 24 ? 0 : clearFloatNum;
      return (
        <TweenOne
          component={Col}
          animation={liAnim}
          key={item.name}
          {...item}
          componentProps={{ md: item.md, xs: item.xs }}
          className={
            !clearFloatNum
              ? `${item.className || ''} clear-both`.trim()
              : item.className
          }
        >
          {/* <TweenOne
            animation={{
              x: '-=10',
              opacity: 0,
              type: 'from',
              ease: 'easeOutQuad',
            }}
            key="img"
            {...childObj.icon}
          >
            <img src={childObj.icon.children} width="100%" alt="img" />
          </TweenOne> */}
          <div {...childObj.textWrapper}>
            <TweenOne
              key="h2"
              animation={childrenAnim}
              component="h2"
              {...childObj.title}
            >
              {childObj.title.children}
            </TweenOne>
            <TweenOne
              key="p"
              animation={{ ...childrenAnim, delay: delay + 200 }}
              component="div"
              {...childObj.content}
            >
              {childObj.content.children}
            </TweenOne>
          </div>
        </TweenOne>
      );
    });
    return (
      <ClientRender>
        <div {...props} {...dataSource.wrapper}>
          <div {...dataSource.page}>
            <div {...dataSource.titleWrapper}>
              {
                isMobile ?
                dataSource.mobileTitleWrapper.children.map(getChildrenToRender)
                :
                dataSource.titleWrapper.children.map(getChildrenToRender)
              }
            </div>
            <OverPack {...dataSource.OverPack}>

              {
                isMobile ? (
                  <div style={{ width: '100%', padding: '0 10%', marginBottom: 0, minHeight: '400px' }}>
                    <QueueAnim key="u" interval={800}  type="scale">
                      <div style={{ width: '100%', margin: '24px 0' }} key="solution_hive_1"><EdgeImg name={'2solution_hive_1.png'} noBase64></EdgeImg></div>
                      <div style={{ width: '100%', margin: '24px 0' }} key="solution_hive_2"><EdgeImg name={'solution_hive_2.png'} noBase64></EdgeImg></div>
                      <div style={{ width: '100%', margin: '24px 0' }} key="solution_hive_3"><EdgeImg name={'solution_hive_3.png'} noBase64></EdgeImg></div>
                      <div style={{ width: '100%', margin: '24px 0' }} key="solution_hive_4"><EdgeImg name={'solution_hive_4.png'} noBase64></EdgeImg></div>
                      <div style={{ width: '100%', margin: '24px 0' }} key="solution_hive_5"><EdgeImg name={'solution_hive_5.png'} noBase64></EdgeImg></div>
                    </QueueAnim>
                  </div>
                )
                : (
                  <div style={{ marginBottom: '-110px', minHeight: '400px'}}>
                    <QueueAnim key="u" interval={800} type="scale" style={{ position: 'relative', top: '-6vw', height: 0, width: '100%', paddingBottom: '57%' }}>
                      <EdgeImg key="solution_hive_1" name={'2solution_hive_1.png'} noBase64 style={{position: 'absolute', width: '29.5%', left: '0', top: '23%'}}></EdgeImg>
                      <EdgeImg key="solution_hive_2" name={'solution_hive_2.png'} noBase64 style={{position: 'absolute', width: '29.5%', left: '23%', top: '46%'}}></EdgeImg>
                      <EdgeImg key="solution_hive_3" name={'solution_hive_3.png'} noBase64 style={{position: 'absolute', width: '29.5%', left: '46%', top: '23%'}}></EdgeImg>
                      <EdgeImg key="solution_hive_4" name={'solution_hive_4.png'} noBase64 style={{position: 'absolute', width: '29.5%', left: '69%', top: '0'}}></EdgeImg>
                      <EdgeImg key="solution_hive_5" name={'solution_hive_5.png'} noBase64 style={{position: 'absolute', width: '29.5%', left: '69%', top: '46%'}}></EdgeImg>
                      <EdgeImg key="bee_logo" name={'bee_logo.png'} noBase64 style={{position: 'absolute', width: '6%', left: '53%', top: '69%', transform: 'rotate(-45deg)'}}></EdgeImg>
                    </QueueAnim>
                  </div>
                )
              }






              {/* <QueueAnim key="u" type="bottom" style={{ marginBottom: isMobile ? 0 : '-110px', minHeight: '400px' }}>
              {
                isMobile ? (
                  <div style={{ width: '100%', padding: '0 16px' }}>
                    <div style={{ width: '100%', margin: '24px 0' }}><EdgeImg name={'2solution_hive_1.png'} loading="eager"></EdgeImg></div>
                    <div style={{ width: '100%', margin: '24px 0' }}><EdgeImg name={'solution_hive_2.png'} loading="eager"></EdgeImg></div>
                    <div style={{ width: '100%', margin: '24px 0' }}><EdgeImg name={'solution_hive_3.png'} loading="eager"></EdgeImg></div>
                    <div style={{ width: '100%', margin: '24px 0' }}><EdgeImg name={'solution_hive_4.png'} loading="eager"></EdgeImg></div>
                    <div style={{ width: '100%', margin: '24px 0' }}><EdgeImg name={'solution_hive_5.png'} loading="eager"></EdgeImg></div>
                  </div>
                )
                : (
                  <div style={{position: 'relative', top: '-110px', height: 0, width: '100%', paddingBottom: '57%'}}>
                    <EdgeImg name={'2solution_hive_1.png'} style={{position: 'absolute', width: '29.5%', left: '0', top: '23%'}}></EdgeImg>
                    <EdgeImg name={'solution_hive_2.png'} style={{position: 'absolute', width: '29.5%', left: '23%', top: '46%'}}></EdgeImg>
                    <EdgeImg name={'solution_hive_3.png'} style={{position: 'absolute', width: '29.5%', left: '46%', top: '23%'}}></EdgeImg>
                    <EdgeImg name={'solution_hive_4.png'} style={{position: 'absolute', width: '29.5%', left: '69%', top: '0'}}></EdgeImg>
                    <EdgeImg name={'solution_hive_5.png'} style={{position: 'absolute', width: '29.5%', left: '69%', top: '46%'}}></EdgeImg>
                    <EdgeImg name={'bee_logo.png'} style={{position: 'absolute', width: '6%', left: '53%', top: '69%', transform: 'rotate(-45deg)'}}></EdgeImg>
                  </div>
                )
              }
              </QueueAnim> */}
            </OverPack>
          </div>
        </div>
      </ClientRender>
    );
  }
}

export default Content400;
