import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { getChildrenToRender } from '../utils';
import { Steps } from 'antd';

const { Step } = Steps;
let interval = {};
class Content14 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
    };
  }
  componentDidMount() {
    interval = setInterval(() => this.setState({step: this.state.step + 1}), 1000)
  }

  componentWillUnmount() {
    clearInterval(interval)
  }

  render() {
    const { step } = this.state;
    const { ...props } = this.props;
    const { dataSource, isMobile, isSmall } = props;
    delete props.dataSource;
    delete props.isMobile;
    return (
      <OverPack {...props} {...dataSource.OverPack}>
        <QueueAnim
          type="bottom"
          leaveReverse
          key="page"
          delay={[0, 100]}
          {...dataSource.titleWrapper}
        >
          {dataSource.titleWrapper.children.map(getChildrenToRender)}
        </QueueAnim>
        {
          isMobile ? (
            <Steps style={isSmall ? {marginLeft: '45%'} : {paddingTop: '32px'}} current={(step%4)} progressDot direction={isSmall ? 'vertical' : 'horizontal'}>
              <Step title="準備期"  />
              <Step title="潛銷期"  />
              <Step title="強銷期"  />
              <Step title="順銷期"  />
            </Steps>
          ) : (
            <QueueAnim
                type="bottom"
                leaveReverse
                key="page"
                delay={[0, 100]}
                {...dataSource.childWrapper}
              >
                {dataSource.childWrapper.children.map(getChildrenToRender)}
              </QueueAnim>
          )
        }
      </OverPack>
    );
  }
}

export default Content14;
